<template>
  <RecordViewWrapper>
    <Main>
      <div v-if="accounts">
        <sdPageHeader :title="i18n.t('accounts.accountsList')"> </sdPageHeader>
        <a-row :gutter="15">
          <a-col class="w-100" :md="24">
            <sdCards headless>
              <div v-if="isLoading" class="spin">
                <a-spin />
              </div>
              <div v-else>
                <TableWrapper class="table-data-view table-responsive">
                  <a-table
                    :pagination="{ pageSize: 10, showSizeChanger: true, hideOnSinglePage: true }"
                    :dataSource="dataSource"
                    :columns="columns"
                  >
                    <template #accountType="record">
                      <router-link :to="'/account/' + record.record.id + '/page/1'">
                        {{ record.record.kind }}
                      </router-link>
                    </template>
                    <template #action="record">
                      <div class="action" v-if="record.record.kind == 'Лицевой' || record.record.kind == 'Personal'">
                        <a-tooltip placement="top" :title="i18n.t('start.deposit')">
                          <router-link :to="'/accounts/deposit/' + record.record.id">
                            <sdButton class="account__button" outlined @click="openModal" size="large" type="success">
                              <sdFeatherIcons type="download" style="color: #20c997" />
                            </sdButton>
                          </router-link>
                        </a-tooltip>
                        <a-tooltip placement="top" :title="i18n.t('start.withdrawal')">
                          <router-link :to="'/accounts/withdrawal/' + record.record.id">
                            <sdButton class="account__button" outlined @click="openModal" size="large" type="primary">
                              <sdFeatherIcons type="upload" style="color: #2c9aea" />
                            </sdButton>
                          </router-link>
                        </a-tooltip>
                      </div>
                      <div
                        class="action"
                        v-else-if="record.record.kind == 'Партнерский' || record.record.kind == 'Partner'"
                      >
                        <a-tooltip placement="top" :title="i18n.t('start.transfer')">
                          <router-link :to="'/accounts/transfer/' + record.record.id">
                            <sdButton class="account__button" outlined size="large" @click="openModal" type="success">
                              <sdFeatherIcons type="git-commit" style="color: #20c997" />
                            </sdButton>
                          </router-link>
                        </a-tooltip>
                        <a-tooltip placement="top" :title="i18n.t('start.withdrawal')">
                          <router-link :to="'/accounts/withdrawal/' + record.record.id">
                            <sdButton class="account__button" outlined @click="openModal" size="large" type="primary">
                              <sdFeatherIcons type="upload" style="color: #2c9aea" />
                            </sdButton>
                          </router-link>
                        </a-tooltip>
                      </div>
                      <div
                        class="action"
                        v-else-if="record.record.kind == 'Токены MTHUB' || record.record.kind == 'MTHUB Tokens'"
                      >
                        <a-tooltip placement="top" :title="i18n.t('start.buy')">
                          <router-link :to="'/accounts/buy/' + record.record.id">
                            <sdButton class="account__button" outlined size="large" @click="openModal" type="success">
                              <sdFeatherIcons type="plus" style="color: #20c997" />
                            </sdButton>
                          </router-link>
                        </a-tooltip>
                        <a-tooltip placement="top" :title="i18n.t('accounts.token.send_is_blocked')">
                          <sdButton class="account__button" outlined size="large" type="primary">
                            <!-- @click="openModal('send')" -->
                            <sdFeatherIcons type="minus" style="color: #2c9aea" />
                          </sdButton>
                        </a-tooltip>
                      </div>
                    </template>
                  </a-table>
                </TableWrapper>
              </div>
            </sdCards>
          </a-col>
        </a-row>
        <ModalCash :visibleModal="visibleModal" @closeModal="closeModal" />
      </div>
      <div class="loading-data" v-else>
        <a-spin size="large"></a-spin>
      </div>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from './style';
import { Modal } from 'ant-design-vue';
import { Main, TableWrapper } from '../styled';
import { useStore } from 'vuex';
import { computed, ref, onMounted, defineAsyncComponent } from 'vue';
import router from '../../routes/protectedRoute';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
const ModalCash = defineAsyncComponent(() => import('@/components/modals/ModalCash'));
const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper, ModalCash },
  setup() {
    const route = useRoute();
    const visibleModal = ref(false);
    const visibleSuccess = ref(false);
    const title = ref('');
    const { state } = useStore();
    const accounts = computed(() => state.accounts.data);
    const isLoading = computed(() => state.accounts.loading);
    const i18n = useI18n();
    const actionType = computed(() => {
      const accType = route.params.actionType;
      accType ? (visibleModal.value = true) : (visibleModal.value = false);
      return accType;
    });
    const actionCashType = computed(() => route.params.actionCashType);
    const accId = computed(() => route.params.accId);
    const dataNotSended = () => {
      visibleModal.value = false;
      Modal.error({
        title: i18n.t('messages.accountsError', { actionType: actionType.value }),
        onOk() {
          closeModal();
        },
        onCancel() {
          closeModal();
        },
      });
    };
    const dataSended = () => {
      visibleModal.value = false;
      Modal.success({
        title: i18n.t('messages.accountsSuccessfull', { actionType: i18n.t('accounts.' + actionType.value) }),
        onOk() {
          closeModal();
        },
        onCancel() {
          closeModal();
        },
      });
    };
    const closeModal = (closeWithoutPush) => {
      visibleModal.value = false;
      if (!closeWithoutPush) {
        router.push('/accounts');
      }
    };
    const openModal = () => {
      visibleModal.value = true;
    };
    onMounted(() => {
      route.params.accId && !visibleModal.value ? router.push('/accounts') : '';
    });
    const columns = computed(() => [
      {
        title: i18n.t('accounts.accountType'),
        dataIndex: 'kind',
        key: 'account_type',
        slots: { customRender: 'accountType' },
      },
      {
        title: i18n.t('accounts.amount'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: i18n.t('accounts.createdAt'),
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: i18n.t('accounts.updatedAt'),
        dataIndex: 'updated_at',
        key: 'updated_at',
      },
      {
        title: i18n.t('accounts.trading.action'),
        dataIndex: 'action',
        key: 'action',
        slots: { customRender: 'action' },
      },
    ]);
    const dataSource = computed(() =>
      accounts.value
        ? accounts.value.map((account, key) => {
            if (account) {
              const { id, attributes } = account;
              const { amount, created_at, kind, updated_at, amount_currency } = attributes;
              return {
                key: key + 1,
                id,
                kind: i18n.t(`accounts.${kind}Name`),
                amount: `${i18n.n(+amount, 'decimal')} ${amount_currency}`,
                created_at: i18n.d(created_at, 'long'),
                updated_at: i18n.d(updated_at, 'long'),
              };
            }
            return {};
          })
        : [],
    );
    return {
      accounts,
      i18n,
      visibleSuccess,
      title,
      actionCashType,
      visibleModal,
      isLoading,
      dataSource,
      columns,
      actionType,
      accId,
      dataSended,
      dataNotSended,
      closeModal,
      openModal,
    };
  },
};

export default ViewPage;
</script>
